import React from "react"
import { graphql } from "gatsby"
import { get } from "lodash"
import { ExternalLink, Layout, TwitterMeta } from "../../components"
import { useSiteMetadata } from "../../utils"
import { Helmet } from "react-helmet"
import "../../assets/stylesheets/application.scss"
import link from '../../assets/images/icons/link.svg'
import presentation from '../../assets/images/icons/presentation.svg'
import video from '../../assets/images/icons/video.svg'

function Mission({ data, location }) {
  const { siteURL, title: siteTitle } = useSiteMetadata()
  const myURL = `${siteURL}${location.pathname}`
  const slug = data.wpMission.slug
  const missionFields = data.wpMission.mission_fields

  const {
    presentationUrl,
    blogPostUrl,
    description,
    videoUrl,
    imageCarousel,
  } = missionFields

  const commander = missionFields.commander || {}
  const subheader = missionFields.teaser
  const externalURL = missionFields.url // URL to external (non-SPACE) site
  const patchURL = get(missionFields, 'missionPatch.sourceUrl')
  const missionType = missionFields.missionType
  const missionStatus = missionFields.missionStatus
  const launchDate = missionFields.launchDate
  const title = missionFields.title
  const teamMembers = missionFields.teamMembers || []
  const tools = missionFields.tools

  return (
    <Layout>
      <Helmet
        title={`${title} - ${siteTitle}`}
        defer={false}
        bodyAttributes={{ class: slug }}
      />
      <TwitterMeta
        title={title}
        image={patchURL}
        url={myURL}
      />

      <header className="page-header">
        <span className="color-tint"></span>
        <div className="text-container">
          <h1>{title}</h1>
          <p>{subheader}</p>
        </div>
      </header>

      <section>
        <div className="content-with-aside-block-container">
          <div className="content-with-aside">
            <div className="content">
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </div>
            <aside>
              <ul>
                <li>
                  <p className="meta-header">Mission Type</p>
                  <p>{missionType}</p>
                </li>
                <li>
                  <p className="meta-header">State</p>
                  <p>{missionStatus}</p>
                  <p>{launchDate}</p>
                </li>
                <li>
                  <p className="meta-header">Tools & Methods</p>
                  <p>{tools}</p>
                </li>
                <li>
                  <ul className="links">
                    <li>
                      { (externalURL || blogPostUrl) &&
                        <ExternalLink
                            className="gradient-button"
                            aria-label="View external website"
                            href={externalURL || blogPostUrl}
                          >
                            <img src={link} alt=""/>
                        </ExternalLink>
                      }
                    </li>
                    <li>
                      { presentationUrl &&
                        <ExternalLink
                            className="gradient-button"
                            aria-label="View Deck"
                            href={presentationUrl}
                          >
                            <img src={presentation} alt=""/>
                        </ExternalLink>
                      }
                    </li>
                    <li>
                      { videoUrl &&
                        <ExternalLink
                          className="gradient-button"
                          aria-label="View Video"
                          href={videoUrl}
                        >
                          <img src={video} alt=""/>
                        </ExternalLink>
                      }
                    </li>
                  </ul>
                </li>
              </ul>
            </aside>
          </div>
          <ul className="team-blocks">
            <li className="team-block">
              {commander?.userInfo?.headshot && <img alt={`${commander.name}`} src={commander.userInfo.headshot.sourceUrl} />}
              <div className="text-block">
                <p className="name">{commander.name}</p>
                <p>Commander</p>
              </div>
            </li>
            {teamMembers.map((teammate) => (
              <li
                key={teammate.name}
                className="team-block"
              >
                {teammate.userInfo.headshot && <img alt={`${teammate.name}`} src={teammate.userInfo.headshot.sourceUrl} />}
                <div className="text-block">
                  <p className="name">{teammate.name}</p>
                  <p>Crew</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </section>

      <section>
        {imageCarousel && imageCarousel.map(({ altText, sourceUrl, }) => <img src={sourceUrl} alt={altText} />)}
      </section>
    </Layout>
  )
}

export default Mission

export const query = graphql`
  query missionPage($id: String!) {
    wpMission(id: { eq: $id }) {
      mission_fields {
        description
        teaser
        url
        missionPatch {
          sourceUrl
        }
        primaryImage {
          sourceUrl
        }
        missionType
        commander {
          name
          userInfo {
            headshot {
              sourceUrl
            }
          }
        }
        tools
        missionStatus
        teamMembers {
          name
          userInfo {
            headshot {
              sourceUrl
            }
          }
        }
        launchDate
        tutorialImage {
          sourceUrl
        }
        blogPostUrl
        primaryColor
        title
        presentationUrl
        videoUrl
        imageCarousel {
          altText
          sourceUrl
        }
      }
      slug
    }
    flybys:
      allWpMission(
        filter: {mission_fields: {missionType: {eq: "Flyby"}}, id: {ne: $id}},
        limit: 3
      ) {
        nodes {
          mission_fields {
            title
          }
          id
          slug
        }
      }
    rockets:
      allWpMission(
        filter: {mission_fields: {missionType: {eq: "Rocket"}}, id: {ne: $id}},
        limit: 3
      ) {
        nodes {
          mission_fields {
            title
          }
          id
          slug
        }
      }
    satellites:
      allWpMission(
        filter: {mission_fields: {missionType: {eq: "Satellite"}}, id: {ne: $id}},
        limit: 3
      ) {
        nodes {
          mission_fields {
            title
          }
          id
          slug
        }
      }
  }
`
